import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'multikart-backend';

  constructor(
    private authService : AuthService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.authService.isLoading$.subscribe({
      next : (value) => {
          if(value === true) {
            this.spinner.show();
          } else {
            this.spinner.hide();
          }
      },
    })
  }
}
