<div class="card">
    <div class="card-header">
        <h4 class="card-title mb-0">Orders for the Past 6 Months</h4>
    </div><!-- end card header -->

    <div class="card-body" *ngIf="dashboardDataSeries.length > 0">
        <apx-chart [series]="columnWithDataChart.series" [chart]="columnWithDataChart.chart"
        [dataLabels]="columnWithDataChart.dataLabels" [plotOptions]="columnWithDataChart.plotOptions"
        [yaxis]="columnWithDataChart.yaxis" [xaxis]="columnWithDataChart.xaxis"
        [fill]="columnWithDataChart.fill" [title]="columnWithDataChart.title" [colors]="columnWithDataChart.colors" dir="ltr"></apx-chart>
    </div><!-- end card-body -->
</div>