import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

export interface User {
  email : string;
  name : string;
  profileImage : string;
  roles : string[];
  isEmailValidated : boolean;
  resetToken : string;
  resetTokenExpiration : Date;
}

export interface authResponse {
  message : string;
  accessToken: string;
  refreshToken: string;
  user: User;
  profile : any
}

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private httpClient : HttpClient,
    private notificationService : NotificationService,
    private router : Router
  ) {

  }

  login(email: string, password: string): Observable<authResponse> {
    this.isLoading$.next(true);
    return this.httpClient.post<authResponse>(`${API_USERS_URL}/auth/login`, {
      email : email, password : password
    }).pipe(
      tap((auth : authResponse) => {
        const rolesToCheck = ['admin'];
        const userRoles = auth?.user?.roles;
        const hasRequiredRole = rolesToCheck.some(role => userRoles?.includes(role));

        if(hasRequiredRole) {

          localStorage.setItem('authToken', JSON.stringify(auth.accessToken));
          localStorage.setItem('refreshToken', JSON.stringify(auth.refreshToken));
          localStorage.setItem('user', JSON.stringify(auth.user));
          localStorage.setItem('profile', JSON.stringify(auth.profile));

          this.currentUserSubject.next(auth.user)
          window.sessionStorage.removeItem('user');
          window.sessionStorage.setItem('user', JSON.stringify(auth.user));
        } else {
          this.logout();
          this.notificationService.showNotification('User role is not authorized!')

          throw Error("User Role Validation Failed");

        }

      }),
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    )
    
  }

  getAuthFromLocalStorage(): any {
    try {
      const lsValue = localStorage.getItem('authToken');
      if (!lsValue) {
        return undefined;
      }
      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  logout(): Observable<any> {
    return this.httpClient.post<any>(`${API_USERS_URL}/auth/logout`, null).pipe(
      tap(res => {
        console.log(res)
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('profile');
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        });
      }
      ),
      catchError((error)=> {
        console.error('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.isLoading$.next(false)
      })
    );
  }

  customErrorMessage () {
    this.notificationService.showNotification('Internal Service Error, Please contact the support service.')
  }

  errorMessageWithError (error: any) {
    this.notificationService.showNotification(error)
  }
  
}
