import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'brands',
    loadChildren: () => import('../../components/brands/brands.module').then(m => m.BrandsModule),
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'reserve',
    loadChildren: () => import('../../components/reserve/reserve.module').then(m => m.ReserveModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];