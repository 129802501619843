import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Product, Variation } from './product.service';
import { PageableRequest } from '../class/pageable';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  constructor(
    private httpClient : HttpClient,
    private notificationService : NotificationService,
    private authService : AuthService
  ) {

  }

  dailyOrderSummary(request: any): Observable<any> {
    this.authService.isLoading$.next(true);
    return this.httpClient.post<any>(`${API_USERS_URL}/dashboard/order-summary-by-day`, request).pipe(
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    ) 
  }
  
  orderSummary(): Observable<any> {
    this.authService.isLoading$.next(true);
    return this.httpClient.get<any>(`${API_USERS_URL}/dashboard/order-summary`).pipe(
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    ) 
  }

  pastSixMonthOrders(): Observable<any> {
    this.authService.isLoading$.next(true);
    return this.httpClient.get<any>(`${API_USERS_URL}/dashboard/past-six-month-orders`).pipe(
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    ) 
  }
  
  customErrorMessage () {
    this.notificationService.showNotification('Internal Service Error, Please contact the support service.')
  }

  errorMessageWithError (error: any) {
    this.notificationService.showNotification(error)
  }
  
}
