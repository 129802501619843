<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col">
            <div class="logo-wrapper"><a [routerLink]="'/dashboard'"><img class="blur-up lazyloaded"
                        src="assets/logo/logo_512x512.png" alt=""></a></div>
        </div>
        <div class="mobile-sidebar col">
            <div class="media-body switch-sm">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <!-- <li>
                    <form class="form-inline search-form">
                        <div class="form-group">
                            <input class="form-control-plaintext" [class.open]="isOpenMobile" type="search"
                                placeholder="Search..">
                            <span class="d-sm-none mobile-search">
                                <app-feather-icons [icon]="'search'" (click)="isOpenMobile=!isOpenMobile">
                                </app-feather-icons>
                            </span>
                        </div>
                    </form>
                </li> -->
                <li>
                    <a href="javascript:void(0)" class="text-dark" toggleFullscreen>
                        <app-feather-icons [icon]="'maximize-2'"></app-feather-icons>
                    </a>
                </li>
                <!-- <li class="onhover-dropdown"><a class="txt-dark" href="javascript:void(0)">
                        <h6>EN</h6>
                    </a>
                    <ul class="language-dropdown onhover-show-div p-20">
                        <li><a href="javascript:void(0)" data-lng="en"><i class="flag-icon flag-icon-is"></i>
                                English</a></li>
                        <li><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i>
                                Spanish</a></li>
                        <li><a href="javascript:void(0)" data-lng="pt"><i class="flag-icon flag-icon-uy"></i>
                                Portuguese</a></li>
                        <li><a href="javascript:void(0)" data-lng="fr"><i class="flag-icon flag-icon-nz"></i> French</a>
                        </li>
                    </ul>
                </li>
                <li class="onhover-dropdown">
                    <span class="badge badge-pill badge-primary pull-right notification-badge">3</span>
                    <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>
                    <ul class="notification-dropdown onhover-show-div">
                        <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0"><span>
                                            <app-feather-icons class="shopping-color" [icon]="'shopping-bag'">
                                            </app-feather-icons>
                                        </span>Your order ready for Ship..!
                                    </h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-success"><span>
                                            <app-feather-icons class="download-color font-success" [icon]="'download'">
                                            </app-feather-icons>
                                        </span>Download Complete</h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-danger"><span>
                                            <app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'">
                                            </app-feather-icons>
                                        </span>250 MB trash files</h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li class="txt-dark"><a href="javascript:void(0)">All</a> notification</li>
                    </ul>
                </li>
                <li><a>
                        <app-feather-icons class="right_side_toggle" [icon]="'message-square'"
                            (click)="right_side_bar()">
                        </app-feather-icons><span class="dot"></span>
                    </a></li> -->
                <li class="onhover-dropdown">
                    <div class="media align-items-center"><img
                            class="align-self-center pull-right img-50 rounded-circle"
                            src="assets/logo/favicon.ico" alt="header-user">

                        <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <!-- <li><a [routerLink]="'/settings/profile'">
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Edit Profile
                            </a></li> -->
                        <!-- <li><a href="javascript:void(0)">
                                <app-feather-icons [icon]="'mail'"></app-feather-icons>Inbox
                            </a></li>
                        <li><a href="javascript:void(0)">
                                <app-feather-icons [icon]="'lock'"></app-feather-icons>Lock Screen
                            </a></li>
                        <li>
                            <a href="javascript:void(0)">
                                <app-feather-icons [icon]="'settings'"></app-feather-icons>Settings
                            </a></li> -->
                        <li>
                            <a (click)="onLogout()">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>