import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private notificationService: ToastrService) { }

  public showNotification(message : string) {

    this.notificationService.show(

      `<div role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <span class="svg-icon svg-icon-2 svg-icon-primary me-3">
          <img alt="Logo" src="./assets/logo/logo_min.png" class="app-sidebar-logo-default">
          </span>
          <strong class="me-auto">${message}</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      
        </div>`,
      '',
      {
        timeOut: 80000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-right',
        toastClass: `ngx-toastr alert alert-dismissible alert-primary alert-notify`,
      }
    );

  }

}