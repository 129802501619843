import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../service/auth.service';
import { NotificationService } from '../service/notification.service';

const TOKEN_HEADER_KEY = 'x-access-token';
const REFRESH_TOKEN_HEADER_KEY = 'x-refresh-token';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private authService : AuthService,
      private jwtHelper :  JwtHelperService,
      private notificationService : NotificationService,
      private router : Router) {}

    intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {

      if (httpRequest.url.includes(`${API_USERS_URL}/auth/login`)){
          return httpHandler.handle(httpRequest);
      }
      if (httpRequest.url.includes(`${API_USERS_URL}/products/find`)){
          return httpHandler.handle(httpRequest);
      }

      let authReq = httpRequest;
      const authToken : any = localStorage.getItem('authToken')
      const refreshToken : any = localStorage.getItem('refreshToken')
      const _token = JSON.parse(authToken)
      const _refreshToken = JSON.parse(refreshToken)

      // console.log(_token )
      // console.log(_refreshToken )
      if (_token != null && _refreshToken != null) {
        authReq = this.addTokenHeader(httpRequest, _token, _refreshToken);
      }

      return httpHandler.handle(authReq).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Handle successful responses here
            // console.log('Response:', event);
  
            // Read response headers
            const headers = event.headers;
            // console.log('Response Headers:', headers);
  
            // Access specific header values
            const accessToken = headers.get('x-access-token');
            const refreshToken = headers.get('x-refresh-token');

            console.log(accessToken)
  
            const existingToken : any = localStorage.getItem('authToken')
            const existingRefreshToken : any = localStorage.getItem('refreshToken')
  
            if(accessToken) {
              console.log(JSON.parse(existingToken) !== accessToken)
              if(JSON.parse(existingToken) !== accessToken) {
                localStorage.setItem('authToken', JSON.stringify(accessToken));
                localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
              }
            }
  
          }
        }),
        catchError((error: HttpErrorResponse) => {
          // Handle error responses here
          console.error('Error:', error);
          return throwError(() => error);
        })
      );
    }

    private addTokenHeader(request: HttpRequest<any>, token: any, refreshToken : any) {
      const headers = new HttpHeaders().set(TOKEN_HEADER_KEY, token)
      .set(REFRESH_TOKEN_HEADER_KEY, refreshToken);
      return request.clone({ headers: headers });
    }

    private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        this.authService.logout().subscribe();
        this.notificationService.showNotification('Session has expired!, please login to the system.')
    }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ];
