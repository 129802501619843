import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { Order } from 'src/app/shared/service/order.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  
  private subs = new SubSink();
  dailySummary: {
    pending : Order[],
    processing : Order[],
    ready_to_shipping : Order[],
    shipped : Order[]
  };
  orderSummary: {
    pending : Order[],
    processing : Order[],
    ready_to_shipping : Order[],
    shipped : Order[]
  };

  constructor(
    private dashboardService : DashboardService
  ) {
    
  }


  ngOnInit() {
    this.getDailySummary();
    this.getOrderSummary();
    // this.getOrderSummaryByYear();

  }

  // getOrderSummaryByYear() {
  //   this.subs.add(
  //     this.dashboardService.pastSixMonthOrders().subscribe({
  //       next:(value) => {
  //           console.log(value);
  //       },
  //     })
  //   )
  // }

  getOrderSummary() {
    this.subs.add(
      this.dashboardService.orderSummary().subscribe({
        next:(value) => {
            // console.log(value);
            this.orderSummary = value;
        },
      })
    )
  }
  getDailySummary() {
    const today = new Date(); // Get the current date
    today.setHours(0, 0, 0, 0); // Set the time to the beginning of the day (midnight)
    
    const tomorrow = new Date(today); // Create a copy of the current date
    tomorrow.setDate(today.getDate() + 1); // Set it to the next day (beginning of the next day)
    

    this.subs.add(
      this.dashboardService.dailyOrderSummary({
        'today' : today,
        'tomorrow' : tomorrow
      }).subscribe({
        next:(value) => {
            // console.log(value);
            this.dailySummary = value;
        },
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

}
