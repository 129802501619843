import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
  UrlTree,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

export const AuthGuardFunction : CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

    const authService: AuthService = inject(AuthService);
    const router: Router = inject(Router);
    const jwtHelper: JwtHelperService = inject(JwtHelperService);

    const token = authService.getAuthFromLocalStorage();

    if (token) {
      if(token != null || token != '' ) {
        if (jwtHelper.decodeToken(token) != null || '' ){
          if(!jwtHelper.isTokenExpired(token)){
            return true;
          }
        }
        // logged in so return true
      }
    }
    // not logged in so redirect to login page with the return url
    router.navigate(['/auth/login'], {
      queryParams: {},
    });
    return false;
}